<template>
  <div
    v-if="validAsset"
    class="w-full h-full bg-scene-gray"
  >==={{ supressControls}}
    <PrevizViewerContainer
      :id="asset.id"
      mode="sequence"
      :container-id="containerId"
      :supress-controls="supressControls"
    />
  </div>
</template>
<script>
import { PrevizViewer } from '@previz/viewer'
import PrevizViewerContainer from './container/PrevizViewer'

let previzviewer = null

export default {
  name: 'SequenceContainer',

  components: {
    PrevizViewerContainer
  },

  props: ['asset', 'playMode', 'exportRequested'],

  computed: {

    containerId () {
      return 'viewerContainer-' + this.asset.id
    },

    supressControls () {
      return this.playMode !== true
    },

    validAsset () {
      return this.asset !== null
    },

    url () {
      if (this.validAsset) return this.asset.url
      return null
    },

    data () {
      return this.$store.getters['sequencer/getDataById'](this.asset.id)
    },

    sequence () {
      if (this.data !== null && this.data.sequence) return this.data.sequence
      return null
    },

    resources () {
      if (this.data !== null && this.data.resources) return this.data.resources
      return null
    },

    hasData () {
      return (this.sequence !== null && this.resources !== null)
    }

  },

  watch: {
    exportRequested () {
      if (previzviewer === null) {
        console.warn('Export request failed - viewer is not attached')
        return
      }

      previzviewer.core.exportPreviz(output => {
        this.$emit('sequence-exported', output)
      })
    },

    hasData: {
      handler: function (value) {
        if (value === true) {
          this.initViewer()
        }
      },
      immediate: true
    },

    validAsset: {
      handler: function (value) {
        if (value === true) {
          this.loadSequence()
        }
      },
      immediate: true
    }
  },

  methods: {
    loadSequence () {
      if (this.validAsset) this.$store.dispatch('sequencer/loadFromUrl', { id: this.asset.id, url: this.url })
    },

    initViewer () {
      if (this.sequence === null || this.resources === null) return

      this.$nextTick(() => {
        let sceneId = this.sequence.scene_id

        if (sceneId === null) {
          console.log('No scene defined?')
        }

        let sceneResource = this.resources.find(res => res.id === sceneId)
        if (sceneResource === undefined) {
          console.log('cant find scene in resources array!!')
        }

        let sceneSubResources = []
        if (sceneResource.sub_resources !== undefined) sceneSubResources = sceneResource.sub_resources

        let previzviewer = new PrevizViewer(sceneResource, sceneSubResources, this.containerId)

        // Now load the sequence
        previzviewer.core.loadSequence(this.sequence, this.resources)

        var event = new CustomEvent('previz-viewer-attached', { detail: previzviewer })
        window.dispatchEvent(event)

        // window.previzviewer = previzviewer
      })
    }
  }
}
</script>
